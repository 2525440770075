/* eslint-disable no-restricted-globals */
/* global screen */

import React  from 'react';
import PropTypes from 'prop-types';
import Layout from 'containers/Layout';
import { Outer, Inner } from 'containers/Wrapper';
import Background from 'containers/Background';
import { Head, Menu } from 'components';
import Footer from 'components/Footer';
import Block1Title from 'blocks/intro/Block-1-Title';
import { Wrapper, Main } from './styled';

function IndexPage() {
  return (
    <Layout>
      <Menu hideSoundButton>
        <Background theme="offblue">
          <Head
            title="home_ourStory"
            description="interface_ourStoryDescription"
            shareImg="intro/sm-story"
          />
          <Wrapper>
            <Main>
              <Block1Title />
            </Main>
            <Outer>
              <Inner>
                <Footer />
              </Inner>
            </Outer>
          </Wrapper>
        </Background>
      </Menu>
    </Layout>
  );
}

IndexPage.propTypes = {
  pathContext: PropTypes.shape({
    locale: PropTypes.string
  }).isRequired
};

export default IndexPage;
