import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-styled-flexboxgrid';
import Link from 'i18n/Link';
import { Outer, Inner } from 'containers/Wrapper';
import Logo from 'components/Logo';
import Collage from 'components/Collage';
import {
  Wrapper,
  InnerWrapper,
  Title,
  Subtitle,
  Buttons,
  StartButton,
  CollageWrapper,
  LogoWrapper
} from './styled';

const collageLayers = ['intro/intro-1', 'intro/intro-2', 'intro/intro-3'];

const Block = () => (
  <Wrapper>
    <CollageWrapper>
      <div className="collage-inner-wrapper">
        <Collage
          layers={collageLayers}
          imageStyle={{
            height: { lg: '1100px', md: '900px', sm: '1100px', xs: '80vh' }
          }}
        />
      </div>
    </CollageWrapper>
    <Outer>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <Inner>
        <InnerWrapper>
          <Row center="xs">
            <Col xs={12} sm={10}>
              <Title>
                <FormattedMessage id="intro_title" />
              </Title>
            </Col>
          </Row>
          <Row center="xs">
            <Col xs={12} sm={8}>
              <Subtitle>
                <FormattedMessage id="intro_heading" />
              </Subtitle>
            </Col>
          </Row>
          <Buttons>
            <Row center="xs">
              <Col xs={7} sm={3} md={2}>
                <Link to="/story/tripartism">
                  <StartButton>
                    <FormattedMessage id="intro_buttonStart" />
                  </StartButton>
                </Link>
              </Col>
            </Row>
          </Buttons>
        </InnerWrapper>
      </Inner>
    </Outer>
  </Wrapper>
);

export default Block;
