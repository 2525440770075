import styled, { css } from 'styled-components';
import { H1, H3 } from 'styles/typography';
import { sans, padding } from 'styles/variables';
import media from 'styles/media';

const minHeight = 62;

export const Wrapper = styled.section`


  ${media.sm`
    min-height: 780px;
    padding: ${padding.top.sm}rem 0 0 0;
  `};

  ${media.xs`
    min-height: ${minHeight}rem;
    overflow-y: visible;
    padding: ${padding.top.xs}rem 0 0 0;
  `};
`;

export const LogoWrapper = styled.div`
  display: inline-block;

  ${media.xs`
    display: none;
  `};
`;

export const InnerWrapper = styled.div`
  width: 100%;
  height: 75vh;
  min-height: 600px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-content: center;
  padding: 5rem 0 0;
  z-index: 1;
  position: relative;

  ${media.xs`
    padding: 20vh 0 0;
    height: auto;
    min-height: ${minHeight - padding.top.xs}rem;
    justify-content: flex-start;
  `};
`;

export const CollageWrapper = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: -5rem;
  opacity: 0.7;
  pointer-events: none;

  display: flex;
  justify-content: center;
  align-items: center;

  .collage-inner-wrapper {
    position: relative;
    width: 70vw;
    height: 100%;

    ${media.md`
      width: 70vw;
    `}

    ${media.sm`
      width: 100vw;
    `}
  }

  ${media.md`
    top: -5.5rem;
  `}

  ${media.portrait`
    left: -15%;
    top: -5%;
  `}

  ${media.xs`
    top: ${padding.top.xs / 1.5}rem;
    width: 100%;
    left: -15%;
  `};
`;

export const Title = styled(H1)`
  ${media.xs`
    text-align: left;
    font-size: 3.8rem;
  `};

  @media only screen and (max-device-width: 320px) {
    font-size: 3rem;
    line-height: 3.5rem;
  }
`;

export const Subtitle = styled(H3)`
  display: block;
  font-size: 1.25rem;
  line-height: 1.875rem;

  ${media.xs`
    text-align: left;
    font-size: 1rem;
  `};
`;

export const Buttons = styled.div`
  flex: 1 1 30vh;
  display: flex;
  flex-flow: column;
  justify-content: center;

  ${media.xs`
    flex: 0 1 auto;
    justify-content: center;
  `};
`;

const button = css`
  font-family: ${sans};
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 62px;
  overflow: hidden;
  top: 0;

  &:last-child {
    margin: 2rem 0 0 0;
  }
`;

export const StartButton = styled.button`
  ${button};
  background-color: #f6b300;
`;

