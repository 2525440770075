import styled from 'styled-components';
import {  padding } from 'styles/variables';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100vh;
  min-height: 750px;
  padding: ${padding.top.lg}rem 0 0 0;
  position: relative;
  overflow: hidden;
`;

export const Main = styled.main`
  flex: 1 0 auto;
`;
